import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';


const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div id="Navbar" className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      <Link to='/'><h1 className='w-full text-3xl font-bold text-[#f60000] m-4'>$DN404</h1></Link>
      <ul className='hidden md:flex'>
        <Link to="/"><li className='p-4 hover:text-[#f54d4d]'>HOME</li></Link>
        <a href="#tokenomics"><li className='p-4 hover:text-[#f54d4d] transition'>Tokenomics</li></a>
        
        <li><button onClick={() => window.location.href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4sy75Ds1NXiDNQUEhjbEpkTBkEGp8hbD7w5GkCNiFT9Q&outputSymbol=DN404&inputAmount=0&fixed=in'} type="button" className='flex bg-gradient-to-bl from-[#8d0909] to-[#3a3a3a] w-[110px] h-[30px] text-white justify-center items-center rounded-md my-0 text-sm font-medium mx-auto md:mx-0 mt-4'>
  Buy $DN404
</button>

</li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'absolute left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <Link to='/'><h1 className='w-full text-3xl font-bold text-[#f60000] m-4'>$DN404</h1></Link>
        <Link to="/"><li className='p-4'>HOME</li></Link>
        <a href="#tokenomics"><li className='p-4 hover:text-[#f54d4d] transition'>Tokenomics</li></a>
          <li className='p-4'></li>
          <li><button onClick={"none"} type="button" className='flex bg-gradient-to-bl from-[#8d0909] to-[#3a3a3a] w-[110px] h-[30px] text-white justify-center items-center rounded-md my-0 text-sm font-medium mx-auto md:mx-0 mt-4'>
                          Buy $DN404
                        </button></li>
      </ul>
    </div>
  );
};

export default Navbar;
