import React from 'react'
import Utility from '../assets/Utility.png'

function Plantimage() {
  return (
    <div className='mx-10 mt-10'>

<div className='flex justify-center items-center px-[25px]'>
          <img className='flex animate-slow-spin md:w-1/4 m-[25px]' src={Utility} alt="Joker" />
        </div>

    </div>
  )
}

export default Plantimage