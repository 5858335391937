import React from 'react';


function Card2() {
  return (
    <div className="screen w-full justify-center items-center border-none p-[25px] ">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center '>
          <div className="md:h-[400px] max-w-lg p-7 bg-gradient-to-rfrom-[#380909] to-[#090010] border border-[#ff0000] rounded-3xl shadow dark:border-[#ff0000] transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Be Early, Be Smart, Invest in DN404</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-700 dark:text-gray-400">
            Embrace the future of NFTs with DN404. Join us on this exciting journey and be part of a community that's shaping the next wave of digital asset ownership. Visit dn404.live to learn more and become an early adopter of the next big thing in the crypto space.
            </p>
            <br />
            
          </div>
        </div>






        <div className='flex justify-center items-center py-10'>
        <div className='flex justify-center items-center py-10'>
          <div className="md:h-[400px] max-w-lg p-7 bg-gradient-to-rfrom-[#380909] to-[#090010] border border-[#ff0000] rounded-3xl shadow dark:border-[#ff0000] transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Why DN404 Tokens Stand Out</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-700 dark:text-gray-400">
            DN404 tokens are designed to redefine the way we think about ownership and investment in the digital age. By enabling fractional ownership of NFTs, DN404 opens the door to unprecedented market liquidity and accessibility. This means that investors now have the opportunity to own a piece of digital art, collectibles, or any asset represented as an NFT, without needing to purchase the entire asset outright. This democratizes access to high-value investments and diversifies investment portfolios like never before.

            </p>
            <br />
            
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Card2;
