import React from 'react'

function Tokencard() {
  return (
    <div className="screen w-auto justify-center items-center border-none">


<div className='flex justify-center items-center  md:p-10 p-[25px]'>
          <div className="max-w-lg p-7 bg-gradient-to-rfrom-[#380909] to-[#090010] border border-[#ff0000] rounded-3xl shadow dark:border-[#ff0000] transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105 ">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Tokenomics of DN404</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-500 dark:text-gray-300">
            DN404's tokenomics strategy is designed to ensure the long-term viability and growth of the 
platform. The distribution of $DN404 tokens is as follows:
            </p>
            <br />
            
          </div>
        </div>


    </div>
  )
}

export default Tokencard