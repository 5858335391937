import React from 'react'

function Kenomics() {
  return (
    <div id="tokenomics" className="md:py-20 py-[20px] md:px-96 px-[25px] font-bold text-2xl transition animate-pulse duration-700 ease-in-out">
      <h1 className='text-white'>Total Supply: 10,000 $DN404</h1>
      <div className="hover:text-red-500 text-[#ffa1a1] transition duration-100 ease-in-out">Team: 5%<hr className="border-t-[1px] border-red-500"/></div>
      <div className="hover:text-red-500 text-[#ffa1a1] transition duration-100 ease-in-out">Development: 11%<hr className="border-t-[1px] border-red-500"/></div>
      <div className="hover:text-red-500 text-[#ffa1a1] transition duration-100 ease-in-out">DEX Listing: 30%<hr className="border-t-[1px] border-red-500"/></div>
      <div className="hover:text-red-500 text-[#ffa1a1] transition duration-100 ease-in-out">CEX Listing: 30%<hr className="border-t-[1px] border-red-500"/></div>
      <div className="hover:text-red-500 text-[#ffa1a1] transition duration-100 ease-in-out">Marketing: 14%<hr className="border-t-[1px] border-red-500"/></div>
      <div className="hover:text-red-500 text-[#ffa1a1] transition duration-100 ease-in-out">Reserved: 10%</div> {/* No line after the last item */}
    </div>
  )
}

export default Kenomics
