import React from 'react';
import Joker from '../assets/Joker.png';
import { Link } from 'react-router-dom';

function Coremetrics() {
  return (
    <div className="screen w-full justify-center items-center border-none ">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center md:p-4 p-[25px]'>
          <div className="max-w-lg p-7 bg-gradient-to-r from-[#380909] to-[#090010] border border-[#ff0000] rounded-3xl shadow dark:border-[#ff0000] transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="text-5xl font-bold tracking-tight text-gray-900 dark:text-white">What is DN-404</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-500 dark:text-gray-300">
            In the rapidly evolving world of digital assets, DN404 tokens emerge as a groundbreaking innovation, setting a new benchmark for the NFT and blockchain community. As the next step beyond the ERC-404 standard, DN404 tokens introduce a revolutionary concept: Divisible NFTs (DNFTs). This unique approach combines the best of both worlds, integrating the liquidity and divisibility of ERC-20 tokens with the uniqueness and collectibility of ERC-721 tokens.
            </p>
            <br />
            <Link to="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4sy75Ds1NXiDNQUEhjbEpkTBkEGp8hbD7w5GkCNiFT9Q&outputSymbol=DN404&inputAmount=0&fixed=in">
              <div className="inline-flex items-center px-3 py-2 text-sm mb-7 font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Join The Revolution
              </div>
            </Link>
          </div>
        </div>
        <div className='flex justify-center items-center md:p-4 p-[25px]'>
          <img className='animate-none md:p-[100px] py-20' src={Joker} alt="Joker" />
        </div>
      </div>
    </div>
  );
}

export default Coremetrics;
