import React from 'react';
import Logo from '../assets/logo512.png'
import {
  FaArrowUp,
  FaDribbbleSquare,
  FaGithubSquare,
  FaTelegram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="flex w-full justify-center items-center px-[25px]">
    <div id="FooterA" className='md:max-w-[1400px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
            <h1 className='w-full text-3xl font-bold text-[#f60000]'>$DN404</h1>
            <p className='flex justify-between md:w-[75%] my-6'> For those looking to be at the forefront of blockchain innovation, DN404 presents an unparalleled opportunity. </p>
        <div className='flex justify-between md:w-[75%] my-6'>
    <FaDribbbleSquare  size={30}/>
    <FaGithubSquare size={30}/>
    <FaTwitterSquare size={30}/>
    <FaTelegram size={30}/>
        </div>
        </div>

<div className='lg:col-span-2 flex justify-between mt-6'>
<div>
    <h6 className='font-medium text-gray-400 '>Company</h6>
    <ul>
        <li className='py-2 text-sm'>CoinMarketCap</li>
        <li className='py-2 text-sm'>Solscan</li>
        <li className='py-2 text-sm'>Smart Contract</li>
        <li className='py-2 text-sm'>Team</li>
      
    </ul>
</div><div>
    <h6 className='font-medium text-gray-400 '>Communication</h6>
    <ul>
        <li className='py-2 text-sm'>Contact</li>
        <li className='py-2 text-sm'>Investor</li>
        <li className='py-2 text-sm'>Developers</li>
        <li className='py-2 text-sm'>Press</li>
        
    </ul>
</div><div>
    
    <ul>
        <li className='py-2 text-sm'></li>
        <li className='py-2 text-sm'></li>
        <li className='py-2 text-sm'></li>
        <li className='py-2 text-sm'></li>
        
    </ul>
</div>
</div>
<div className='lg:col-span-3 flex justify-between'>
<div>
       <img className='flex w-[150px] h-[150px]' src={Logo} alt="$DN404 Token" />
    </div><div>
        <ul>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
        </ul>
    </div>
    <div className='mt-5'>
    <a href="#Navbar">    
        <FaArrowUp size={80}/>
            <p className='flex justify-center mt-3'>
                PAGE UP
            </p></a>
    
    </div>

</div>
</div>
    </div>
  )
}

export default Footer;
