import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Logo from './components/Logo';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Beta from './components/Beta';
import Documentation from './components/Documentation';
import Kenomics from './components/Kenomics';
import Firstview from './components/Firstview';
import Coremetrics from './components/Coremetrics';
import Card1 from './components/Card1';
import Tokencard from './components/Tokencard';
import Plantimage from './components/Plantimage';
import Card2 from './components/Card2';
import Faq from './components/Faq';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
         <Routes>
          <Route path="/Beta" element={<Beta />} /> {/* This will only show when the URL path is '/about' */}
          <Route path="/Documentation" element={<Documentation />} /> {/* This will only show when the URL path is '/Documentation' */}
          <Route path="/" element={<> {/* Wrap existing components in an empty fragment */}
            
            <Firstview />
            <Logo />
            <Coremetrics />
            <Tokencard />
            <Kenomics />
            <Plantimage />
            <Faq />
            <Card1 />
            <Card2 />
            <Newsletter />
         
            
            
          </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
