import React from 'react';


function Card1() {
  return (
    <div className="screen w-full justify-center items-center border-none p-[25px]">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center py-10 md:p-1 '>
          <div className="h-[600px] max-w-lg p-7 bg-gradient-to-rfrom-[#380909] to-[#090010] border border-[#ff0000] rounded-3xl shadow dark:border-[#ff0000] transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">A Golden Opportunity for Early Investors</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-500 dark:text-gray-300">
            For those looking to be at the forefront of blockchain innovation, DN404 presents an unparalleled opportunity. Being early adopters of DN404 tokens not only places investors ahead in the NFT space but also positions them to reap significant returns on investment (ROI) as the market for divisible NFTs expands. The novelty and potential of DN404 to transform NFT transactions make it a compelling addition to any investor's portfolio, promising both short-term gains and long-term growth.
            </p>
            <br />
            
          </div>
        </div>






        <div className='flex justify-center items-center py-10'>
        <div className='flex justify-center items-center py-10 '>
          <div className="md:h-[600px] max-w-lg p-7 bg-gradient-to-rfrom-[#380909] to-[#090010] border border-[#ff0000] rounded-3xl shadow dark:border-[#ff0000] transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Join the DN404 Revolution</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-500 dark:text-gray-300">
            As DN404 tokens prepare to make their debut on the Solana blockchain, with plans for future migration to the Ethereum blockchain, this is your chance to be part of a pioneering movement. The transition to the DN-404 standard on Ethereum will further solidify the token's position in the market, ensuring holders of DN404 on Solana are strategically positioned to benefit from the airdrop and subsequent growth on Ethereum.<br /><br />

Investing in DN404 tokens is not just about owning a piece of digital art or an asset; it's about believing in the future of blockchain technology and its capacity to offer more equitable, efficient, and innovative solutions for ownership and investment.

            </p>
            <br />
            
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
