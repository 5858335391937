import React from 'react'
import '../assets/Firstview.css';
import Logo from '../assets/logo512.png';

const Firstview = () => {
  return (



<div className="main-section-background flex md:w-max-[768px] lg:w-max[1024px] xl:w-max-[1280px] justify-center items-center ">


        <div className='flex justify-center pt-[80px] lg:pt-[250px]'>
        
       </div>
        <div>
       
        

    
        
            
                    <div><h2 className=' animate-pulse items-center justidy-between text-white text-8xl font-bold '><img src={Logo} alt='$DN404' /></h2></div>
           

     

    

</div>

        


        </div>

   

    
  )
}

export default Firstview