import React from 'react';

const Logo = () => {
  return (
    <div className='text-white md:pb-[50px] pb-[100px]'>
<div className='flex items-end justify-center mt-[10px]'>
</div>

      <div className='max-w-[800px] mt-[10px] w-full h-auto mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#00df9a] font-bold p-2'>
          
        </p>
        <h1 className='text-[#5fe7f7] font-bold md:text-8xl sm:text-5xl text-6xl p-2'>$DN404
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-3xl sm:text-4xl text-xl font-bold py-4'>
            The First DN404 Token
          </p>

          
          
        </div>
        <div className='justify-center items-center pb-2 mt-4 text-4xl'>
         <h1 className='font-bold bg-gradient-to-r from-[#ff0000] via-[#a80000] to-[#a6a6a6] inline-block text-transparent bg-clip-text md:text-4xl text-2xl '>On Solana Blockchain</h1>
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500 p-5 md:p-1'>This is your chance to be early to take off!</p>
        <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4sy75Ds1NXiDNQUEhjbEpkTBkEGp8hbD7w5GkCNiFT9Q&outputSymbol=DN404&inputAmount=0&fixed=in"><button className='border-white border-[2px] bg-gradient-to-bl from-[#010101] to-[#272727] w-[200px] rounded-md mt-[40px] my-6 mx-auto py-3 text-[#69e0f8] hover:bg-gradient-to-bl hover:text-[#efefef] hover:from-[#021a6f] hover:to-[#14503d] transition duration-100 ease-in-out text-2xl font-bold'>Buy $DN404</button></a>

      </div>
    </div>
  );
};

export default Logo;
