import React, { useState } from 'react';

function Faq() {
  const [isOpen, setIsOpen] = useState(null);

  const toggleFAQ = index => {
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <div className="w-full px-4 my-20 md:px-52 text-2xl">
        <h1 className='text-[#f98787] font-bold text-3xl px-1'>FAQ </h1><br />
      <div className="faq-item mb-4 p-5 shadow-lg shadow-red-900" onClick={() => toggleFAQ(1)} style={{border: '1px solid #f60000', cursor: 'pointer'}}>
        <div style={{color: '#f60000'}}>+ How to Buy DN404 Tokens:</div>
        {isOpen === 1 && (
          <div className='text-[#f5c8c8] text-lg flex flex-col gap-4 p-4'>
            <p className='text-[#6afffd]'><strong>Use the "Buy DN404" button on this page or follow the steps below:</strong></p>
            <p><strong>Step 1:</strong> Create a Phantom wallet at the official Phantom website or app store. This wallet supports Solana (SOL) tokens.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p><strong>Step 2:</strong> Buy Solana (SOL) from a centralized exchange (CEX) like Coinbase, Binance, or Kraken. Transfer SOL to your Phantom wallet.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p><strong>Step 3:</strong> Go to a DEX that lists DN404 (e.g., Raydium or Orca), and connect your Phantom wallet.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p><strong>Step 4:</strong> Swap SOL for DN404. Use the token address <code className='text-[#6afffd]'>XXXXXXXXXXXXXXXXXXXXXX</code> to find DN404. This ensures you're buying the correct token.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p><strong>Step 5:</strong> Confirm the swap and approve the transaction in your Phantom wallet. Once confirmed, DN404 tokens will appear in your wallet.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p><strong>Important:</strong> Always use the correct DN404 token address to avoid scams. Happy trading!</p>
          </div>
        )}
      </div>
      <div className="faq-item mb-4 p-5 shadow-lg shadow-red-900" onClick={() => toggleFAQ(2)} style={{border: '1px solid #f60000', cursor: 'pointer'}}>
        <div style={{color: '#f60000'}}>+ Why DN404 on Solana?</div>
        {isOpen === 2 && (
          <div className='text-[#f5c8c8] text-lg flex flex-col gap-4 p-4'>
            <p>Choosing Solana for DN404 not only ensures faster transactions and lower fees but also makes it easier and more affordable for early adopters. By being one of the first to support DN404 on Solana, you stand to benefit from:</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p>- Lower Transaction Fees: Solana's efficiency means you pay less to buy, sell, or transfer DN404.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p>- Faster Transactions: Solana's blockchain offers quick processing, so you don't have to wait.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p>- Early Adopter Advantages: Get DN404 standard tokens airdropped to your wallet without any transaction fees.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p>- Innovative Technology: Be part of a new wave of blockchain technology with unique benefits.</p>
            <hr style={{borderTop: '1px solid #f60000'}} />
            <p>Embrace the future of cryptocurrency by investing in DN404 on Solana today.</p>
          </div>
        )}
      </div>
      <div className="faq-item mb-4 p-5 shadow-lg shadow-red-900" onClick={() => toggleFAQ(3)} style={{border: '1px solid #f60000', cursor: 'pointer'}}>
  <div style={{color: '#f60000'}}>+ What is Next?</div>
  {isOpen === 3 && (
    <div className='text-[#f5c8c8] text-lg flex flex-col gap-4 p-4'>
      <p>The journey with DN404 is just beginning. Future benefits for our community include token airdrops, exclusive NFT drops, and significant ROI opportunities. We are committed to continuous development, with exciting announcements on the horizon. Stay tuned and be part of a project that grows with its community.</p>
      <hr style={{borderTop: '1px solid #f60000'}} />
      <p>- Exclusive NFT Drops: Gain access to unique digital assets that offer both value and utility within our ecosystem.</p>
      <hr style={{borderTop: '1px solid #f60000'}} />
      <p>- ROI Opportunities: Our innovative approach aims to maximize your investment's potential, ensuring a prosperous future for all stakeholders.</p>
      <hr style={{borderTop: '1px solid #f60000'}} />
      <p>Our vision for DN404 extends far beyond the present, with a roadmap designed to deliver lasting value. Your journey with us is valued, and we're excited to unveil what comes next.</p>
    </div>
  )}
</div>

<div className="faq-item mb-4 p-5 shadow-lg shadow-red-900" onClick={() => toggleFAQ(4)} style={{border: '1px solid #f60000', cursor: 'pointer'}}>
  <div style={{color: '#f60000'}}>+ Who is the Team?</div>
  {isOpen === 4 && (
    <div className='text-[#f5c8c8] text-lg flex flex-col gap-4 p-4'>
      <p>At the heart of DN404 is a group of developers who have contributed to numerous successful projects. However, our true strength lies in our community. This project is about you, the supporters, and the collective vision we share for the future of blockchain and digital assets.</p>
      <hr style={{borderTop: '1px solid #f60000'}} />
      <p>- Experienced Developers: Our team's background spans across blockchain, finance, and technology, ensuring a solid foundation for DN404.</p>
      <hr style={{borderTop: '1px solid #f60000'}} />
      <p>- Community-Driven: We believe in the power of our community to guide the project's direction, making DN404 a truly collaborative effort.</p>
      <hr style={{borderTop: '1px solid #f60000'}} />
      <p>Your trust and investment are safe with us. We're committed to transparency, ongoing development, and delivering exciting announcements that will continue to enhance the value and utility of DN404. Together, we're not just building a token; we're shaping the future.</p>
    </div>
  )}
</div>

    </div>
  );
}

export default Faq;
